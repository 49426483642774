import get from 'lodash/get';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getStandards } from 'helpers/settings';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import DataSources from 'modules/DataSources';
import Information from 'modules/Information';
import Hcp from 'modules/Hcp';
import Patient from 'modules/Patient';
import { activeVisit } from 'modules/Visit/selectors';
import * as constants from './constants';
import * as selectors from './selectors';
import { PatientProfile } from './types';


export const useConnectorConsentTemplate = (connectorType: ConnectorType) => {
  const consentsTemplate = useSelector(Information.selectors.consentsTemplate);
  const { legalConsentApprovals } = useSelector(Information.selectors.information);

  if (connectorType === constants.CONNECTOR_TYPES.MDA) {
    return [];
  }

  const noApprovalConsentTemplate: LegalConsent[] = [];
  const connectorConsentTemplate = consentsTemplate
    && consentsTemplate.filter((ct) => ct.type === 'ThirdPartyConnector');

  connectorConsentTemplate.forEach((cct) => {
    const cctApproval = legalConsentApprovals.find((lca) => lca.legalConsentId === cct.legalConsentId);
    if (!cctApproval || !cctApproval.decision) {
      noApprovalConsentTemplate.push(cct);
    }
  });

  return noApprovalConsentTemplate;
};


export const usePhiSet = (): PhiSet => {
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  const isHcp = activeProfileType === Account.constants.PROFILE_TYPES.HCP;
  const phiSetSelector = isHcp ? Hcp.selectors.phiSet : Patient.selectors.phiSet;
  return useSelector(phiSetSelector);
};


export const usePhiSetDocumentId = (): string => {
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  const isHcp = activeProfileType === Account.constants.PROFILE_TYPES.HCP;
  const phiSetDocumentIdSelector = isHcp ? Hcp.selectors.phiSetDocumentId : Patient.selectors.phiSetDocumentId;
  return useSelector(phiSetDocumentIdSelector);
};


export const usePatientProfile = (): PatientProfile => {
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  const patientProfileSelector = activeProfileType === Account.constants.PROFILE_TYPES.HCP
    ? Hcp.selectors.activePatient
    : Account.selectors.patientProfileExtended;
  return useSelector(patientProfileSelector);
};


export const useStandards = () => {
  const phiSet: PhiSet = usePhiSet();
  const activeClinicMembership: ClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const clinicSettings = get(activeClinicMembership, 'clinic.settings');
  const countrySettings = useSelector(Account.selectors.countrySettings);
  return useMemo(
    (): GlucoseStandards => getStandards(phiSet, countrySettings, clinicSettings),
    [phiSet, countrySettings, clinicSettings],
  );
};


export const useActiveVisit = (): Visit => {
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  if (activeProfileType !== Account.constants.PROFILE_TYPES.HCP) {
    return null;
  }
  return useSelector(activeVisit);
};


export const useIsProfileActivationInProgress = () => {
  const isActivatePatientInProgress = useSelector(Hcp.selectors.isActivatePatientInProgress);
  const isFetchPhiSetInProgress = useSelector(CloudDrive.selectors.isFetchPhiSetInProgress);
  const isFetchReadingsInProgress = useSelector(CloudDrive.selectors.isFetchReadingsInProgress);
  const isSyncInProgress = useSelector(CloudDrive.selectors.isSyncInProgress);
  const isDataSourcesSyncInProgress = useSelector(DataSources.selectors.isSyncInProgress);

  return isActivatePatientInProgress || isFetchPhiSetInProgress || isFetchReadingsInProgress
    || isSyncInProgress || isDataSourcesSyncInProgress;
};


export const useIsDisabled = (patientProfile, isProfileActivationInProgress) => {
  const deviceData = useSelector(selectors.deviceData);
  const deviceSerialNumberToken = get(deviceData, 'deviceSerialNumberToken');
  const hasFetchPhiSetErrors = useSelector(CloudDrive.selectors.hasFetchPhiSetErrors);
  const hasFetchReadingsErrors = useSelector(CloudDrive.selectors.hasFetchReadingsErrors);

  return !patientProfile || !deviceSerialNumberToken || isProfileActivationInProgress
    || hasFetchPhiSetErrors || hasFetchReadingsErrors;
};
