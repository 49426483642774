import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';
import { useConnectionStatusChange, useDeviceDataChange } from './hooks';


interface Props {
  connectorType: ConnectorType,
  standards: GlucoseStandards,
  onAddWarning: (warningType: WarningType) => void,
  onSetComponent: (componentName: string) => void,
  onCancel: () => void,
}


const Downloading: FC<Props> = ({ connectorType, onAddWarning, onSetComponent, onCancel }) => {
  useConnectionStatusChange(connectorType, onSetComponent, onCancel);
  useDeviceDataChange(onAddWarning, onSetComponent);

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.downloading.header} /></h3>
      { /* <p className="modal__info"><FormattedMessage {...messages.downloading.info} /></p> */ }
      <div className={styles.illustration}>
        <img src="/assets/svg/loader.svg" className="rotatingLoader" alt="" />
      </div>
      <p className="modal__info text--center mb-5"><FormattedMessage {...messages.downloading.waiting} /></p>
    </div>
  );
};

export default Downloading;
