import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import ExclamationNegative from 'svg/exclamation-negative.svg';
import Loader from 'svg/loader.svg';
import Account from 'modules/Account';
import { useActiveVisit, useIsProfileActivationInProgress, usePatientProfile } from '../../../../hooks';
import messages from '../../../../messages';
import styles from '../../DownloadDataModal.pcss';


interface Props {
  onSetComponent: (componentName: string) => void,
}


const PatientData: FC = () => {
  const patientProfile = usePatientProfile();
  const isProfileActivationInProgress = useIsProfileActivationInProgress();
  if (isProfileActivationInProgress) {
    return (
      <Loader className={`${styles.results__patientLoader} rotatingLoader`} />
    );
  }

  if (!patientProfile) {
    return (
      <div className={styles.results__noPatient}>
        <ExclamationNegative className={styles.results__noPatient__icon} />
        <span><FormattedMessage {...messages.labels.noPatientMatched} /></span>
      </div>
    );
  }

  const { avatar, firstName, lastName } = patientProfile;
  return (
    <div className="row align-items-center">
      <div className="col-auto pr-0">
        <Avatar
          avatarBase64={avatar}
          name={[firstName, lastName]}
          className={styles.results__avatar}
          imgClassName={styles.results__avatar__img}
          initialsClassName={styles.results__avatar__initials}
        />
      </div>
      <div className="col">
        <p data-hj-suppress className={styles.results__patient__name}>{ firstName } { lastName }</p>
      </div>
    </div>
  );
};


const ChoosePatient: FC<Props> = ({ onSetComponent }) => {
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  const isPwd = activeProfileType === Account.constants.PROFILE_TYPES.PWD;
  const patientProfile = usePatientProfile();
  const activeVisit = useActiveVisit();

  if (isPwd || activeVisit) {
    return null;
  }

  const onChoosePatient = () => onSetComponent('ChoosePatient');

  return (
    <div className="col text--right">
      <Button
        styleModifier="transparent"
        labelMessage={patientProfile ? messages.buttons.changePatient : messages.buttons.choosePatient}
        className="btn--no-size text--primary m-0"
        onClick={onChoosePatient}
        id="choosePatient"
      />
    </div>
  );
};


const Patient: FC<Props> = (props) => (
  <div className={styles.results__patient}>
    <div className={styles.results__patient__inner}>
      <div className="col">
        <PatientData {...props} />
      </div>
      <div className="col">
        <ChoosePatient {...props} />
      </div>
    </div>
  </div>
);

export default Patient;
