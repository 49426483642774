import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import Account from 'modules/Account';
import messages from '../../../../messages';
import styles from '../../DownloadDataModal.pcss';
import BgmResults from './BgmResults';
import Patient from './Patient';


interface Props {
  onSetComponent: (componentName: string) => void,
  onCancel: () => void,
}


const b = (chunk) => <b>{ chunk }</b>;


const Info: FC = () => {
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  if (activeProfileType !== Account.constants.PROFILE_TYPES.HCP) {
    return null;
  }

  return (
    <p className="brand__paragraph mt-0 mb-8">
      <FormattedMessage {...messages.infos.patientsResults} values={{ b }} />
    </p>
  );
};


const DownloadSummary: FC<Props> = ({ onSetComponent, onCancel }) => (
  <div className={cn(styles.modal__content, 'h-auto')}>
    <Info />
    <Patient {...{ onSetComponent }} />
    <BgmResults {...{ onCancel }} />
  </div>
);

export default DownloadSummary;
