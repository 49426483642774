import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useAction } from 'hooks';
import Account from 'modules/Account';
import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';
import { useActiveVisit, usePhiSet, useStandards } from '../../../hooks';
import { hasMismatchBetweenSummaryAndStandards, hasMismatchBetweenTimes } from '../helpers';


export const useConnectionStatusChange = (
  connectorType: ConnectorType,
  onSetComponent: (componentName: string) => void,
  onCancel: () => void,
) => {
  const connectionId = useSelector(selectors.connectionId);
  const connectionStatus: ConnectionStatus = useSelector(selectors.connectionStatus);
  const connectionError = useSelector(selectors.connectionError);
  const activeVisit = useActiveVisit();
  const onGetDeviceData = useAction(actions.getDeviceData, connectorType, connectionId, activeVisit);

  useEffect(() => {
    if (connectionStatus === constants.CONNECTION_STATUSES.ERROR) {
      if (connectionError === 'NoLicense') {
        onSetComponent('NoLicense');
        return;
      }
      onSetComponent('ConnectionFailed');
      return;
    }
    if (connectionStatus === constants.CONNECTION_STATUSES.CANCELED) {
      onCancel();
      return;
    }
    if (connectionStatus === constants.CONNECTION_STATUSES.TIMEOUT) {
      onSetComponent('Timeout');
      return;
    }
    if (connectionStatus === constants.CONNECTION_STATUSES.SUCCESS) {
      onGetDeviceData();
    }
  }, [connectionStatus]);
};


export const useDeviceDataChange = (
  onAddWarning: (warningType: WarningType) => void,
  onSetComponent: (componentName: string) => void,
) => {
  const activeProfileType = useSelector(Account.selectors.activeProfileType);
  const deviceData = useSelector(selectors.deviceData);
  const phiSet = usePhiSet();
  const standards = useStandards();

  useEffect(() => {
    if (!deviceData) {
      return;
    }
    const { serialNumber, summary, deviceDate } = deviceData;
    let shouldShowWarning = false;
    if (hasMismatchBetweenSummaryAndStandards(summary, standards)) {
      shouldShowWarning = true;
      onAddWarning(constants.WARNING_TYPES.MISMATCH_TARGET_RANGES);
    }
    if (!deviceDate) {
      shouldShowWarning = true;
      onAddWarning(constants.WARNING_TYPES.UNAVAILABLE_TIME);
    } else if (hasMismatchBetweenTimes(moment(), moment(deviceDate))) {
      shouldShowWarning = true;
      onAddWarning(constants.WARNING_TYPES.MISMATCH_TIME);
    }
    if (phiSet && activeProfileType === Account.constants.PROFILE_TYPES.PWD) {
      if (phiSet.imports) {
        const importedDevices = Object.keys(phiSet.imports);
        if (!importedDevices.includes(serialNumber)) {
          shouldShowWarning = true;
          onAddWarning(constants.WARNING_TYPES.NEW_DEVICE_CONNECTED);
        }
      } else {
        shouldShowWarning = true;
        onAddWarning(constants.WARNING_TYPES.NEW_DEVICE_CONNECTED);
      }
    }
    if (shouldShowWarning) {
      onSetComponent('WarningsBeforeDownloadData');
    } else {
      onSetComponent('Results');
    }
  }, [deviceData]);
};
