import React, { FC } from 'react';
import Hcp from 'modules/Hcp';
import * as constants from '../../../constants';


interface Props {
  onSetComponent: (componentName: string) => void,
}


const AddPatient: FC<Props> = ({ onSetComponent }) => {
  const onClose = () => onSetComponent('DownloadSummary');

  return (
    <Hcp.partials.AddPatientForm
      modalId={constants.DOWNLOAD_DATA_MODAL}
      isAutoActivation
      onClose={onClose}
    />
  );
};


export default AddPatient;
