import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Account from 'modules/Account';
import * as constants from '../../../constants';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';
import AppStoreLinks from './AppStoreLinks';


interface WarningProps {
  warningType: WarningType,
  warningsLen: number,
}

interface Props extends WarningProps {
  idx: number,
}


const b = (chunk: ReactNode): ReactNode => <b>{ chunk }</b>;


const getInfoMessage = (
  warningType: WarningType,
  warningsLen: number,
  activeProfileType: string,
): MessageDescriptor => {
  const singleTxt = warningsLen === 1 ? 'Single' : '';
  switch (warningType) {
    case constants.WARNING_TYPES.MISMATCH_TARGET_RANGES: {
      if (activeProfileType === Account.constants.PROFILE_TYPES.PWD) {
        return messages.infos[`warningMismatchTargetRanges${singleTxt}`];
      }
      return messages.infos[`warningMismatchTargetRanges${singleTxt}Hcp`];
    }
    default: {
      return messages.infos[`warning${warningType}${singleTxt}`];
    }
  }
};


const WarningHeader: FC<WarningProps> = ({ warningType, warningsLen }) => {
  if (warningsLen === 1) {
    return null;
  }

  return (
    <h3 className="brand__paragraph--bold">
      <FormattedMessage {...messages.headers[`warning${warningType}`]} />
    </h3>
  );
};


const WarningInfo: FC<WarningProps> = ({ warningType, warningsLen }) => {
  const activeProfileType = useSelector(Account.selectors.activeProfileType);
  const countryId = useSelector(Account.selectors.countryId);
  const message = getInfoMessage(warningType, warningsLen, activeProfileType);

  return (
    <div>
      <p className="brand__paragraph brand__fontSecondary">
        <FormattedMessage {...message} values={{ b }} />
      </p>
      {
        warningType === constants.WARNING_TYPES.MISMATCH_TARGET_RANGES
          && activeProfileType === Account.constants.PROFILE_TYPES.PWD
          && <AppStoreLinks countryId={countryId} />
      }
    </div>
  );
};


const WarningBox: FC<Props> = ({ warningType, warningsLen, idx }) => (
  <div className="row align-items-center">
    <div className="col">
      <WarningHeader {...{ warningType, warningsLen }} />
      <WarningInfo {...{ warningType, warningsLen }} />
      { (warningsLen > 1 && idx !== warningsLen - 1) && <div className={styles.warnings__hr} /> }
    </div>
  </div>
);

export default WarningBox;
