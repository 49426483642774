import React, { FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { compare } from 'compare-versions';
import get from 'lodash/get';
import has from 'lodash/has';
import { AppContext } from 'context';
import * as selectors from '../../selectors';
import { usePhiSet } from '../../hooks';
import Initializing from './Initializing';
import InstallDownloader from './InstallDownloader';
import InstallingDownloader from './InstallingDownloader';
import InstalledDownloader from './InstalledDownloader';
import DownloaderOutdated from './DownloaderOutdated';
import NoConnection from './NoConnection';


interface Props {
  onSetComponent: (componentName: string) => void,
  onSetChannel: (channel: string) => void,
}


const components = {
  Initializing,
  InstallDownloader,
  InstallingDownloader,
  InstalledDownloader,
  DownloaderOutdated,
  NoConnection,
};


const getIsDownloaderOutdated = (downloader: MDA, downloaderConfig: MDAConfig) => {
  const clientVersion = get(downloader, 'mdaVersion');
  if (!clientVersion) {
    return false;
  }
  const minVersion = get(downloaderConfig, 'minVersion');
  return compare(clientVersion, minVersion, '<');
};


const MdaInit: FC<Props> = ({ onSetComponent, onSetChannel, ...props }) => {
  const { downloader: downloaderConfig } = useContext(AppContext);
  const downloader = useSelector(selectors.downloader);
  const phiSet = usePhiSet();

  const [componentName, setComponentName] = useState<string>('Initializing');

  const Component = get(components, componentName, null);

  const onSetComponentName = (newComponentName: string) => {
    if (!has(components, newComponentName)) {
      onSetComponent(newComponentName);
      return;
    }
    setComponentName(newComponentName);
  };

  const onInitialized = () => {
    const isDownloaderOutdated = getIsDownloaderOutdated(downloader, downloaderConfig);
    if (isDownloaderOutdated) {
      onSetComponentName('DownloaderOutdated');
      return;
    }
    const lastConnectionType = get(phiSet, 'summaryData.lastConnectionType');
    onSetChannel(lastConnectionType);
    onSetComponent('Connect');
  };

  return <Component {...{ onInitialized, onSetComponent: onSetComponentName, ...props }} />;
};

export default MdaInit;
