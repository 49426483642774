import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styles from '../../DownloadDataModal.pcss';


interface Props {
  labelMessage: MessageDescriptor,
  value: number,
  icon?: React.JSX.Element,
  SubSegment?: FC,
}


const ResultsTableSegment: FC<Props> = ({ labelMessage, value, icon, SubSegment }) => (
  <div className={styles.strippedTable__segment}>
    <div className="row no-gutters justify-content-between px-5 py-3">
      <div className="d-flex align-items-center">{ icon }<FormattedMessage {...labelMessage} /></div>
      <div>{ value }</div>
    </div>
    {
      SubSegment
      && (
        <div className={styles.strippedTable__subSegment}>
          <SubSegment />
        </div>
      )
    }
  </div>
);

export default ResultsTableSegment;
