import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './DownloadDataModal.pcss';


interface Props {
  connectorType: ConnectorType,
  status: DownloaderStatus,
  onSetComponent: (componentName: string) => void,
}


const Timeout: FC<Props> = ({ status, connectorType, onSetComponent }) => {
  let componentName = 'ChooseConnection';
  if (connectorType === constants.CONNECTOR_TYPES.SCC) {
    componentName = 'Initializing';
  } else if (status !== constants.DOWNLOADER_STATUSES.READY) {
    const downloader = useSelector(selectors.downloader);
    if (downloader.mdaVersion) {
      componentName = 'NoConnection';
    } else {
      componentName = 'InstallDownloader';
    }
  }

  const onTryAgain = () => onSetComponent(componentName);

  return (
    <div className={styles.modal__content}>
      <h3 className="modal__subheader "><FormattedMessage {...messages.timeout.header} /></h3>
      <div className={styles.illustration}>
        <img src="/assets/svg/timeout.svg" alt="" />
      </div>
      <div className="modal__actions">
        <Button
          styleModifier="primary"
          labelMessage={messages.buttons.tryAgain}
          className="btn--block btn--filled"
          onClick={onTryAgain}
        />
      </div>
    </div>
  );
};


export default Timeout;
