import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { encrypt, getKeyFromPem } from 'helpers/crypto';
import { useAction } from 'hooks';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Hcp from 'modules/Hcp';
import Patient from 'modules/Patient';
import Notifications from 'modules/Notifications';
import Statistics from 'modules/Statistics';
import * as selectors from '../../../../../selectors';
import { PatientProfile } from '../../../../../types';
import { usePhiSet, usePhiSetDocumentId, useStandards } from '../../../../../hooks';


export const useStoreReadings = (
  patientProfile: PatientProfile,
  onCancel: () => void,
) => {
  const deviceData = useSelector(selectors.deviceData);
  const account = useSelector(Account.selectors.account);
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  const isHcp = activeProfileType === Account.constants.PROFILE_TYPES.HCP;
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const passphrase = useSelector(Account.selectors.passphrase);
  const isStoreReadingsInProgress = useSelector(CloudDrive.selectors.isStoreReadingsInProgress);
  const hasStoreReadingsErrors = useSelector(CloudDrive.selectors.hasStoreReadingsErrors);

  const phiSet = usePhiSet();
  const phiSetDocumentId = usePhiSetDocumentId();
  const successAction = isHcp ? Hcp.actions.setImportedReadings : Patient.actions.onStoreReadingsSuccess;
  const standards = useStandards();

  const totalReadingsCount = useRef(0);
  const prevIsStoreReadingsInProgressRef = useRef(isStoreReadingsInProgress);

  const [isInProgress, setIsInProgress] = useState<boolean>(false);

  const sendClinicPatientNewReadingsAddedNotification = useAction(
    Notifications.actions.sendClinicPatientNewReadingsAddedNotification,
  );
  const updateProfile = useAction(Hcp.actions.updatePatient, patientProfile);
  const storeReadings = useAction(
    CloudDrive.actions.storeReadings, deviceData, phiSet, phiSetDocumentId, patientProfile, successAction,
  );
  const sendStatistics = useAction(Statistics.actions.sendStatistics);
  const sendStatisticsForClinic = useAction(Statistics.actions.sendStatisticsForClinic);

  const onImport = () => {
    setIsInProgress(true);
    if (isHcp) {
      const { deviceSerialNumberToken } = deviceData;
      const serialNumberTokens = get(patientProfile, 'devicesSerialNumberTokens') || [];
      if (!includes(serialNumberTokens, deviceSerialNumberToken)) {
        const devicesSerialNumberTokens = [...serialNumberTokens, deviceSerialNumberToken];
        updateProfile({ devicesSerialNumberTokens }, activeClinicMembership);
      }
    }
    storeReadings();
  };

  const onSendNewReadingsNotification = (readingsCount) => {
    const { clinicId, name: clinicName, publicKey } = activeClinicMembership.clinic;
    const { id, firstName, lastName } = patientProfile;
    const pubKeyObj = getKeyFromPem(publicKey);
    const encryptedClinicPatientProfileId = encrypt(id, pubKeyObj);
    sendClinicPatientNewReadingsAddedNotification({
      clinicId,
      clinicName,
      patientFirstName: firstName,
      patientLastName : lastName,
      encryptedClinicPatientProfileId,
      readingsCount,
    });
  };

  const onSendStats = () => {
    if (!isHcp) {
      return sendStatistics({ ...patientProfile, ...account }, passphrase, phiSet, deviceData, standards);
    }
    return sendStatisticsForClinic(patientProfile, phiSet, deviceData, standards, activeClinicMembership);
  };

  useEffect(() => {
    totalReadingsCount.current = get(phiSet, 'summaryData.totalReadingsCount');
  }, []);

  useEffect(() => {
    if (isInProgress && prevIsStoreReadingsInProgressRef.current !== isStoreReadingsInProgress) {
      if (!isStoreReadingsInProgress) {
        if (!hasStoreReadingsErrors) {
          const updatedTotalReadingsCount = get(phiSet, 'summaryData.totalReadingsCount');
          if (isHcp && updatedTotalReadingsCount > totalReadingsCount.current) {
            onSendNewReadingsNotification(updatedTotalReadingsCount - totalReadingsCount.current);
          }
          onSendStats();
          onCancel();
        } else {
          setIsInProgress(false);
        }
      }
      prevIsStoreReadingsInProgressRef.current = isStoreReadingsInProgress;
    }
  }, [isStoreReadingsInProgress]);

  return { isInProgress, onImport };
};
