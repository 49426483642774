import has from 'lodash/has';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MessageDescriptor } from 'react-intl';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { AppContext } from 'context';
import history from 'helpers/history';
import { getSlug } from 'helpers/urlTools';
import Account from 'modules/Account';
import Hcp from 'modules/Hcp';
import * as selectors from '../../../selectors';
import { useActiveVisit } from '../../../hooks';
import messages from '../../../messages';
import DownloadSummary from './DownloadSummary';
import AddPatient from './AddPatient';
import ChoosePatient from './ChoosePatient';


interface Props {
  onSetComponent: (componentName: string) => void,
  onSetHeaderMessage: (headerMessage?: MessageDescriptor) => void,
  onCancel: () => void,
}


const components = {
  DownloadSummary,
  AddPatient,
  ChoosePatient,
};


const useDevicePatientSetup = () => {
  const activeProfileType: ProfileType = useSelector(Account.selectors.activeProfileType);
  const isHcp = activeProfileType === Account.constants.PROFILE_TYPES.HCP;
  const activeVisit = useActiveVisit();
  if (!isHcp || activeVisit) {
    return;
  }
  const deviceData = useSelector(selectors.deviceData);
  const patients = useSelector(Hcp.selectors.patients);
  const patientProfile = useSelector(Hcp.selectors.activePatient);
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const { getUrl } = useContext(AppContext);

  useEffect(() => {
    const currentPatientId = get(patientProfile, 'id');
    const deviceSerialNumberToken = get(deviceData, 'deviceSerialNumberToken');
    const patientsFilter = (patient) => includes(patient.devicesSerialNumberTokens, deviceSerialNumberToken);
    const deviceUser = find(patients, patientsFilter);
    if (deviceUser && deviceUser.id !== currentPatientId) {
      const { organizationUID, name } = get(activeClinicMembership, 'clinic', {});
      const clinicSlug = getSlug(name);
      const patientSlug = getSlug(`${deviceUser.firstName} ${deviceUser.lastName}`);
      const url = getUrl(
        'hcp-results',
        { clinicSlug, organizationUID, patientSlug, patientId: deviceUser.id },
      );
      history.push(url);
    }
  }, []);
};


const Results: FC<Props> = ({ onSetComponent, onSetHeaderMessage, onCancel }) => {
  const [componentName, setComponentName] = useState<string>('DownloadSummary');

  useEffect(() => {
    const headerMessage = componentName === 'AddPatient'
      ? messages.addPatient.header
      : messages.choosePatient.header;
    onSetHeaderMessage(headerMessage);
    return () => onSetHeaderMessage();
  }, [componentName]);

  useDevicePatientSetup();

  const onSetComponentName = (newComponentName: string) => {
    if (!has(components, newComponentName)) {
      onSetComponent(newComponentName);
      return;
    }
    setComponentName(newComponentName);
  };

  const Component = get(components, componentName, null);

  return <Component {...{ onSetComponent: onSetComponentName, onSetHeaderMessage, onCancel }} />;
};

export default Results;
