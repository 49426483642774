import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useAction } from 'hooks';
import Link from 'components/Link';
import Switch from 'components/Switch';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Patient from 'modules/Patient';
import Meter from 'modules/Meter';
import PatientResults from 'modules/PatientResults';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import messages from '../../../messages';
import TopBar from '../../../partials/TopBar';
import ProfileMenu from '../TopBar/ProfileMenu';
import Notifications from '../TopBar/Notifications';
import ReportButton from '../TopBar/ReportButton';
import styles from '../TopBar/TopBar.pcss';


interface Props {
  isReadOnly: boolean,
}


const DownloadMeter: FC<Props> = ({ isReadOnly }) => {
  const activeProfileType = useSelector(Account.selectors.activeProfileType);
  const isSyncInProgress = useSelector(Patient.selectors.isSyncInProgress);
  const isFetchPhiSetInProgress = useSelector(CloudDrive.selectors.isFetchPhiSetInProgress);
  const isFetchReadingsInProgress = useSelector(CloudDrive.selectors.isFetchReadingsInProgress);
  const isDisabled = isFetchPhiSetInProgress || isFetchReadingsInProgress || isSyncInProgress;

  if (activeProfileType === Account.constants.PROFILE_TYPES.CG) {
    return null;
  }

  return (
    <div className={styles.element}>
      <Meter.partials.DownloadDataFromMeterBtn
        className="btn--block btn--filled"
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
      />
    </div>
  );
};


const ActivePatientOptions: FC = () => {
  const activePatient = useSelector(Account.selectors.patientProfile); // diff
  const phiSet = useSelector(Patient.selectors.phiSet); // diff
  const phiSetDocumentId = useSelector(Patient.selectors.phiSetDocumentId); // diff
  // TODO: Refactor PatientResults usage
  const deviceMode = useSelector(PatientResults.selectors.deviceMode);
  const resultsIsInProgress = useSelector(PatientResults.selectors.resultsIsInProgress);
  const featureToggles = useSelector(App.selectors.featureToggles);

  const setReadings = useAction(Patient.actions.setReadings); // diff
  const setDeviceMode = useAction(PatientResults.actions.setDeviceMode);
  const storeSelectedReportTypes = useAction(PatientResults.actions.storeSelectedReportTypes);
  const prepareAgpPreview = useAction(AmbulatoryGlucoseProfile.actions.prepareAgpPreview);
  const goToReportsView = useAction(App.actions.goToReportsView);

  if (!phiSet) {
    return null;
  }

  const onSetDeviceMode = () => {
    if (deviceMode === 'BGM') {
      prepareAgpPreview(activePatient, phiSet, phiSetDocumentId, setReadings);
    }
    setDeviceMode(deviceMode === 'AGP' ? 'BGM' : 'AGP');
  };

  return (
    <>
      <Switch
        isDisabled={resultsIsInProgress}
        labelMessage={messages.sidebar.labels.agpView}
        isActive={deviceMode === 'AGP'}
        onClick={onSetDeviceMode}
      />
      <ReportButton
        storeSelectedReportTypes={storeSelectedReportTypes}
        goToReportsView={goToReportsView}
        isInProgress={resultsIsInProgress}
        featureToggles={featureToggles}
      />
    </>
  );
};


const PwdTopBar: FC<Props> = ({ isReadOnly }) => {
  useStyles(styles);

  return (
    <TopBar>
      <div className="d-flex align-items-center">
        <div className={styles.element}>
          <Link to="/">
            <img src="/assets/img/gco-logo.png" className={styles.logo} alt="GlucoContro.online" />
          </Link>
        </div>
        <DownloadMeter {...{ isReadOnly }} />
      </div>
      <div className={cn(styles.element, 'd-flex align-items-center')}>
        <ActivePatientOptions />
        <div className={cn(styles.separate)} />
        <Notifications />
        <ProfileMenu />
      </div>
    </TopBar>
  );
};

export default PwdTopBar;
