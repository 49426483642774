import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';
import useStyles from 'isomorphic-style-loader/useStyles';
import isEmpty from 'lodash/isEmpty';
import { useAction } from 'hooks';
import Link from 'components/Link';
import Switch from 'components/Switch';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Hcp from 'modules/Hcp';
import HcpExports from 'modules/HcpExports';
import Meter from 'modules/Meter';
import PatientResults from 'modules/PatientResults';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import Visit from 'modules/Visit';
import * as constants from '../../../constants';
import messages from '../../../messages';
import TopBar from '../../../partials/TopBar';
import ActiveClinicMembershipBtn from '../TopBar/ActiveClinicMembershipBtn';
import EnrollCodeBtn from '../TopBar/EnrollCodeBtn';
import ProfileMenu from '../TopBar/ProfileMenu';
import Notifications from '../TopBar/Notifications';
import ReportButton from '../TopBar/ReportButton';
import styles from '../TopBar/TopBar.pcss';


interface Props {
  isReadOnly: boolean,
}


const DownloadMeter: FC<Props> = ({ isReadOnly }) => {
  const isEnrollPatientInProgress = useSelector(Hcp.selectors.isEnrollPatientInProgress);
  const isMergePatientInProgress = useSelector(Hcp.selectors.isMergePatientInProgress);
  const isStartPatientVisitInProgress = useSelector(Hcp.selectors.isStartPatientVisitInProgress);
  const isEndPatientVisitInProgress = useSelector(Hcp.selectors.isEndPatientVisitInProgress);
  const isSyncInProgress = useSelector(Hcp.selectors.isSyncInProgress);
  const isFetchPhiSetInProgress = useSelector(CloudDrive.selectors.isFetchPhiSetInProgress);
  const isFetchReadingsInProgress = useSelector(CloudDrive.selectors.isFetchReadingsInProgress);
  const isDisabled = isEnrollPatientInProgress || isMergePatientInProgress || isFetchPhiSetInProgress
    || isFetchReadingsInProgress || isStartPatientVisitInProgress || isEndPatientVisitInProgress || isSyncInProgress;

  return (
    <div className={styles.element}>
      <Meter.partials.DownloadDataFromMeterBtn
        className="btn--block btn--filled"
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
      />
    </div>
  );
};


const ActivePatientOptions: FC = () => {
  const activePatient = useSelector(Hcp.selectors.activePatient); // diff
  const phiSet = useSelector(Hcp.selectors.phiSet); // diff
  const phiSetDocumentId = useSelector(Hcp.selectors.phiSetDocumentId); // diff
  // TODO: Refactor PatientResults usage
  const deviceMode = useSelector(PatientResults.selectors.deviceMode);
  const resultsIsInProgress = useSelector(PatientResults.selectors.resultsIsInProgress);
  const featureToggles = useSelector(App.selectors.featureToggles);

  const setReadings = useAction(Hcp.actions.setReadings); // diff
  const setDeviceMode = useAction(PatientResults.actions.setDeviceMode);
  const storeSelectedReportTypes = useAction(PatientResults.actions.storeSelectedReportTypes);
  const prepareAgpPreview = useAction(AmbulatoryGlucoseProfile.actions.prepareAgpPreview);
  const goToReportsView = useAction(App.actions.goToReportsView);

  if (!phiSet) {
    return null;
  }

  const onSetDeviceMode = () => {
    if (deviceMode === 'BGM') {
      prepareAgpPreview(activePatient, phiSet, phiSetDocumentId, setReadings);
    }
    setDeviceMode(deviceMode === 'AGP' ? 'BGM' : 'AGP');
  };

  return (
    <>
      <Switch
        isDisabled={resultsIsInProgress}
        labelMessage={messages.sidebar.labels.agpView}
        isActive={deviceMode === 'AGP'}
        onClick={onSetDeviceMode}
      />
      <ReportButton
        storeSelectedReportTypes={storeSelectedReportTypes}
        goToReportsView={goToReportsView}
        isInProgress={resultsIsInProgress}
        featureToggles={featureToggles}
      />
    </>
  );
};


const SearchPatientList: FC = () => {
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership); // TODO: Move into children
  const search = useSelector(Hcp.selectors.searchSelector('topBar'));
  const [activePatientsLength, setActivePatientsLength] = useState<number>(0);

  if (isEmpty(search)) {
    return null;
  }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ height: 0 }}
        animate={activePatientsLength === 0 ? { display: 'none' } : { height: 'auto', display: 'block' }}
        exit={{ height: 0 }}
        className={styles.searchModal}
      >
        <Hcp.partials.ActivePatients
          id="topBar"
          activeClinicMembership={activeClinicMembership}
          isWithoutLoad
          maxPatientLength={Hcp.constants.PATIENTS_LIST_LENGTH}
          setActivePatientsLength={setActivePatientsLength}
        />
      </motion.div>
    </AnimatePresence>
  );
};


const PatientListOptions: FC = () => {
  // TODO: Make components injection in the top bar!!!
  const route = useSelector(App.selectors.route);
  if (!route || route.name !== 'patients-list') {
    return null;
  }
  return <HcpExports.components.ExportDataMenu isInProgress={false} />;
};


const HcpTopBar: FC<Props> = ({ isReadOnly }) => {
  const intl = useIntl();
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership); // TODO: Move into children
  const activeVisit = useSelector(Visit.selectors.activeVisit);
  const isDisabled = !!activeVisit;
  const tooltipText = activeVisit ? intl.formatMessage(App.messages.infos.menuDisabledIfActiveVisit) : '';
  useStyles(styles);

  return (
    <TopBar>
      <div className="d-flex align-items-center">
        <div
          className={cn(styles.element, { [styles['element--isDisabled']]: isDisabled })}
          data-for="globalTooltip"
          data-tip={tooltipText}
        >
          <Link to={activeVisit ? '' : '/'}>
            <img src="/assets/img/gco-logo.png" className={styles.logo} alt="GlucoContro.online" />
          </Link>
        </div>
        <div
          className={cn(styles.element, { [styles['element--isDisabled']]: isDisabled })}
          data-for="globalTooltip"
          data-tip={tooltipText}
        >
          <ActiveClinicMembershipBtn />
        </div>
        <div className={styles.element}>
          <EnrollCodeBtn />
        </div>
        <DownloadMeter {...{ isReadOnly }} />
      </div>
      <div
        className={
          cn(styles.element, styles.search, 'd-flex align-items-center', {
            [styles['element--isDisabled']]: isDisabled,
          })
        }
        data-for="globalTooltip"
        data-tip={tooltipText}
      >
        <Hcp.partials.SearchPatients
          forId="topBar"
          activeClinicMembership={activeClinicMembership}
          formName={constants.TOPBAR_SEARCH_FORM}
          clearOnBlur
          tooltipText={tooltipText}
          isDisabled={isDisabled}
        />
        <SearchPatientList />
      </div>
      <div className={cn(styles.element, 'd-flex align-items-center')}>
        <ActivePatientOptions />
        <PatientListOptions />
        <div className={cn(styles.separate)} />
        <div
          className={cn({ [styles['element--isDisabled']]: isDisabled })}
          data-for="globalTooltip"
          data-tip={tooltipText}
        >
          <Notifications />
        </div>
        <div
          className={cn({ [styles['element--isDisabled']]: isDisabled })}
          data-for="globalTooltip"
          data-tip={tooltipText}
        >
          <ProfileMenu />
        </div>
      </div>
    </TopBar>
  );
};

export default HcpTopBar;
