import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import throttle from 'lodash/throttle';
import { Toaster } from 'react-hot-toast';
import Button from 'components/Form/Button';
import countrySettingsShape from 'shapes/countrySettingsShape';
import App from 'modules/App';
import Account from 'modules/Account';
import Meter from 'modules/Meter';
import Notifications from 'modules/Notifications';
import Patient from 'modules/Patient';
import PatientResults from 'modules/PatientResults';
import Hcp from 'modules/Hcp';
import Visit from 'modules/Visit';
import ClinicManagement from 'modules/ClinicManagement';
import DataSources from 'modules/DataSources';
import messages from '../../messages';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as constants from '../../constants';
import ClinicInfo from './ClinicInfo';
import ClinicSetLicence from './ClinicSetLicence';
import PatientSidebar from './PatientSidebar';
import HcpSidebar from './HcpSidebar';
import HcpTopBar from './HcpTopBar';
import PwdTopBar from './PwdTopBar';
import styles from './MainLayout.pcss';


class MainLayout extends React.Component {

  static propTypes = {
    // Explicit props
    metaTitleMessage                : PropTypes.object,
    metaDescriptionMessage          : PropTypes.object,
    isHcpAccount                    : PropTypes.bool.isRequired,
    isCareGiverAccount              : PropTypes.bool.isRequired,
    isForceHcpClinics               : PropTypes.bool,
    isSidebarOff                    : PropTypes.bool,
    isReadOnly                      : PropTypes.bool,
    // Implicit props
    account                         : PropTypes.shape({ encryptedPassphrase: PropTypes.string }),
    activeProfileType               : Account.shapes.profileType,
    activeProfile                   : PropTypes.object,
    activeClinicMembership          : Account.shapes.clinicMembership,
    activeOrganizationMembership    : PropTypes.object,
    visitNotesFloatingModal         : App.shapes.floatingModal,
    activeVisit                     : Visit.shapes.visit,
    clinicMemberships               : PropTypes.arrayOf(ClinicManagement.shapes.clinicMembership),
    countrySettings                 : countrySettingsShape,
    devices                         : PropTypes.arrayOf(App.shapes.device),
    dataSources                     : PropTypes.arrayOf(DataSources.shapes.dataSource),
    patients                        : PropTypes.array,
    children                        : PropTypes.node,
    openModalId                     : PropTypes.string,
    route                           : App.shapes.route,
    printMode                       : PropTypes.bool,
    direction                       : PropTypes.string,
    menuOpen                        : PropTypes.bool,
    selectedMenuItem                : PropTypes.string,
    windowWidth                     : PropTypes.number,
    lastReceivedNotification        : Notifications.shapes.notification,
    // Implicit Actions
    onValidateAccount               : PropTypes.func,
    onFetchCountrySettings          : PropTypes.func,
    onFetchDevices                  : PropTypes.func,
    onManageOrganizationMemberships : PropTypes.func,
    onFetchDataSources              : PropTypes.func,
    onCheckPendingMemberships       : PropTypes.func,
    onFetchClinicMemberships        : PropTypes.func,
    onCheckPatientsWithoutLeadingHcp: PropTypes.func,
    onCloseDropdown                 : PropTypes.func,
    setPrintMode                    : PropTypes.func,
    setMenuOpen                     : PropTypes.func,
    setSelectedMenuItem             : PropTypes.func,
    setWindowWidth                  : PropTypes.func,
    setDashboardLayout              : PropTypes.func,
    // onStartWebsocket      : PropTypes.func,
  };


  static defaultProps = {
    isSidebarOff: false,
  };


  constructor(props) {
    super(props);
    props.onValidateAccount();
    if (!props.countrySettings) {
      props.onFetchCountrySettings();
    }
    if (!props.devices.length) {
      props.onFetchDevices();
    }
    if (!props.dataSources.length) {
      props.onFetchDataSources();
    }

    this.updateScreenWidth = throttle((shouldSetBaseMenuOpen) => this.onUpdateScreenWidth(shouldSetBaseMenuOpen), 300);
  }


  componentDidMount() {
    const { activeProfileType, activeClinicMembership } = this.props;
    // this.props.onStartWebsocket();
    window.addEventListener('resize', this.updateScreenWidth);
    this.updateScreenWidth(true);

    if (activeProfileType === Account.constants.PROFILE_TYPES.HCP && activeClinicMembership) {
      this.props.onCheckPendingMemberships();
      this.props.onFetchClinicMemberships(activeClinicMembership.clinicId);
    }
  }


  componentDidUpdate(prevProps) {
    const {
      lastReceivedNotification, activeProfileType, activeClinicMembership,
      clinicMemberships, patients,
      visitNotesFloatingModal, windowWidth,
    } = this.props;

    if (
      activeProfileType === Account.constants.PROFILE_TYPES.HCP
      && activeClinicMembership
      && activeClinicMembership.isAdmin
      && (prevProps.clinicMemberships !== clinicMemberships || prevProps.patients !== patients)
      && patients.length && clinicMemberships.length
    ) {
      this.props.onCheckPatientsWithoutLeadingHcp();
    }

    if (
      windowWidth < constants.PINNED_MENU_NOTES_BREAKPOINT
        && prevProps.visitNotesFloatingModal !== visitNotesFloatingModal
        && visitNotesFloatingModal
    ) {
      this.props.setMenuOpen(false);
    }

    if (
      activeProfileType === Account.constants.PROFILE_TYPES.HCP
        && prevProps.activeClinicMembership !== activeClinicMembership && activeClinicMembership
    ) {
      this.props.onCheckPendingMemberships();
      this.props.onFetchClinicMemberships(activeClinicMembership.clinicId);
    }

    if (
      activeProfileType === Account.constants.PROFILE_TYPES.HCP
        && prevProps.lastReceivedNotification !== lastReceivedNotification
        && lastReceivedNotification
        && lastReceivedNotification.notificationTrigger
        === Notifications.constants.NOTIFICATIONS_TRIGGERS.CLINIC_MEMBERSHIP_APPROVED
    ) {
      window.location.reload();
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenWidth);
  }


  onUpdateScreenWidth(shouldSetBaseMenuOpen) {
    if (!process.env.BROWSER) {
      return;
    }
    this.props.setWindowWidth(window.innerWidth, shouldSetBaseMenuOpen === true);
  }


  onKeyDown(evt) {
    // If ESC
    if (evt.keyCode === 27) {
      this.props.onCloseDropdown();
      if (!this.isPinnedMenu) {
        this.props.setMenuOpen(false);
      }
      this.props.setSelectedMenuItem(null);
    }
  }


  onClickLayout() {
    this.props.onCloseDropdown();
    if (!this.isPinnedMenu) {
      this.props.setMenuOpen(false);
    }
    this.props.setSelectedMenuItem(null);
  }


  onClickSidebar(evt) {
    evt.stopPropagation();
    this.props.onCloseDropdown();
  }


  onCloseReport() {
    this.props.setDashboardLayout(PatientResults.constants.DASHBOARD_LAYOUTS.basic);
    this.props.setPrintMode(false);
  }


  get isPrintMode() {
    return this.props.printMode;
  }


  get isPinnedMenu() {
    const { windowWidth, menuOpen, activeVisit, visitNotesFloatingModal } = this.props;
    const breakpoint = visitNotesFloatingModal
      ? constants.PINNED_MENU_NOTES_BREAKPOINT
      : constants.PINNED_MENU_BREAKPOINT;
    return (windowWidth >= breakpoint && menuOpen && !activeVisit);
  }


  renderClosePrintMode() {
    return (
      <Button
        type="button"
        styleModifier="secondary"
        className={cn('btn--filled', styles.closePrintModeBtn)}
        onClick={() => this.onCloseReport()}
      >
        <FormattedMessage {...messages.printMode.closePrintMode} />
      </Button>
    );
  }


  renderTopBar() {
    const { isReadOnly } = this.props;
    if (this.props.activeProfileType === Account.constants.PROFILE_TYPES.HCP) {
      return <HcpTopBar isReadOnly={isReadOnly} />;
    }
    return <PwdTopBar isReadOnly={isReadOnly} />;
  }


  renderSidebar() {
    const {
      activeProfileType, activeVisit, visitNotesFloatingModal,
      isSidebarOff, isReadOnly, direction,
      setMenuOpen, setSelectedMenuItem, menuOpen, selectedMenuItem,
    } = this.props;
    if (isSidebarOff) {
      return null;
    }

    const Sidebar = activeProfileType === Account.constants.PROFILE_TYPES.HCP ? HcpSidebar : PatientSidebar;

    return (
      <div
        className={
          cn(styles.sidebarHolder, {
            [styles['sidebarHolder--extended']]: this.isPinnedMenu,
            [styles['sidebarHolder--hidden']]  : activeVisit && visitNotesFloatingModal,
          })
        }
        onClick={(evt) => this.onClickSidebar(evt)}
        onKeyDown={(evt) => this.onKeyDown(evt)}
      >
        <div className={
          cn('col-auto d-print-none', {
            [styles.hide]: this.isPrintMode,
          })
        }
        >
          <Sidebar
            isReadOnly={isReadOnly}
            direction={direction}
            setMenuOpen={setMenuOpen}
            setSelectedMenuItem={setSelectedMenuItem}
            menuOpen={menuOpen && !activeVisit}
            selectedMenuItem={selectedMenuItem}
            activeVisit={activeVisit}
            isPinnedMenu={this.isPinnedMenu}
          />
        </div>
      </div>
    );
  }


  renderAlertsBus() {
    // if (this.props.openModalId) {
    //   return null;
    // }
    const activeClinicMembership = this.props.activeProfileType === Account.constants.PROFILE_TYPES.HCP
      ? this.props.activeClinicMembership
      : null;
    return (
      <App.components.AlertsBus
        className="px-6"
        isGlobal
        activeClinicMembership={activeClinicMembership}
      />
    );
  }


  renderContent() {
    const { activeProfileType, activeClinicMembership, activeOrganizationMembership } = this.props;
    let content = this.props.children;
    if (activeProfileType === Account.constants.PROFILE_TYPES.HCP) {
      if (activeOrganizationMembership && activeOrganizationMembership.status === 'Pending') {
        content = <ClinicInfo isMembershipPending clinic={activeOrganizationMembership.organization} />;
      } else if (activeOrganizationMembership && activeOrganizationMembership.organization.status === 'Pending') {
        content = <ClinicInfo isClinicPending clinic={activeOrganizationMembership.organization} />;
      } else if (activeClinicMembership && !activeClinicMembership.clinic.storageAccount && !activeClinicMembership.isAdmin) {
        content = <ClinicInfo isClinicNotConfigured clinic={activeClinicMembership.clinic} />;
      } else if (activeClinicMembership && activeClinicMembership.clinic.clinicStatus === 'WaitingForLicence') {
        content = <ClinicSetLicence activeClinicMembership={activeClinicMembership} />;
      }
    }
    return (
      <div className="content">
        { this.renderAlertsBus() }
        { content }
      </div>
    );
  }


  renderNonCGModals() {
    if (this.props.isCareGiverAccount) {
      return null;
    }
    return (
      <Meter.components.DownloadDataModal />
    );
  }


  renderHCPModals() {
    if (this.props.activeProfileType !== Account.constants.PROFILE_TYPES.HCP) {
      return null;
    }
    return (
      <>
        <Hcp.components.MergePatientModal />
        <Hcp.components.DeclineEnrollModal />
        <Hcp.components.InvitePatientModal />
        <Hcp.components.RemovePatientModal />
      </>
    );
  }


  renderPWDModals() {
    if (this.props.activeProfileType !== Account.constants.PROFILE_TYPES.PWD) {
      return null;
    }
    return (
      <>
        <Patient.components.EnrollInClinicModal />
        <Patient.components.ConfirmDataSharingModal
          activeProfileType={this.props.activeProfileType}
          activeProfile={this.props.activeProfile}
        />
        <Patient.components.RevokeAccessModal />
      </>
    );
  }


  renderPWDOrCGModals() {
    if (
      this.props.activeProfileType !== Account.constants.PROFILE_TYPES.CG
      && this.props.activeProfileType !== Account.constants.PROFILE_TYPES.PWD
    ) {
      return null;
    }
    return (
      <>
        <Patient.components.InviteFamilyMembersModal />
        <Patient.components.RevokeFamilyAccessModal />
      </>
    );
  }


  render() {
    const titleMessage = this.props.metaTitleMessage
      ? this.props.metaTitleMessage
      : { ...messages.meta.title };

    const descriptionMessage = this.props.metaDescriptionMessage
      ? this.props.metaDescriptionMessage
      : { ...messages.meta.description };

    return (
      <App.components.LanguageProvider>
        <div
          className={
            cn({
              [styles.root] : !this.isPrintMode,
              [styles.print]: this.isPrintMode,
              print         : this.isPrintMode,
            })
          }
          onClick={() => this.onClickLayout()}
          onKeyDown={(evt) => this.onKeyDown(evt)}
        >
          <App.components.IntlHelmet
            titleMessage={titleMessage}
            descriptionMessage={descriptionMessage}
          />
          <App.components.Cookies />
          <div
            className={
              cn({
                [styles.container]      : !this.isPrintMode,
                [styles.layoutContainer]: !this.isPrintMode,
              })
            }
          >
            { this.renderTopBar() }
            <div
              className={
                cn({
                  [styles.layout]: !this.isPrintMode,
                })
              }
            >
              <div
                className={
                  cn({
                    'row no-gutters'         : !this.isPrintMode,
                    [styles.sidebarContainer]: !this.isPrintMode,
                  })
                }
              >
                { this.renderSidebar() }
                { this.isPrintMode && this.renderClosePrintMode() }
              </div>
              { this.renderContent() }
            </div>
          </div>
          <Account.components.ReauthToCCModal />
          <Notifications.partials.NotificationsSettingsModal />
          <Notifications.partials.NotificationsHistoryModal />
          { this.renderNonCGModals() }
          { this.renderHCPModals() }
          { this.renderPWDModals() }
          { this.renderPWDOrCGModals() }
          {
            process.env.BROWSER
            && (
              <>
                <ReactTooltip
                  id="globalTooltip"
                  place="bottom"
                  type="dark"
                  effect="solid"
                  className="tooltip"
                />
                <Toaster position="top-right" />
              </>
            )
          }
        </div>
      </App.components.LanguageProvider>
    );
  }

}


const mapStateToProps = (state) => {
  const activeProfileType = Account.selectors.activeProfileType(state);
  const activeVisit = activeProfileType === Account.constants.PROFILE_TYPES.HCP
    ? Visit.selectors.activeVisit(state)
    : null;
  const clinicMemberships = activeProfileType === Account.constants.PROFILE_TYPES.HCP
    ? ClinicManagement.selectors.clinicMemberships(state)
    : [];
  return {
    // isClientInitialized: getIsClientInitialized(state, props),
    account                     : Account.selectors.account(state),
    activeProfileType,
    activeProfile               : Account.selectors.activeProfile(state),
    activeClinicMembership      : Account.selectors.activeClinicMembership(state),
    activeOrganizationMembership: Account.selectors.activeOrganizationMembership(state),
    isHcpAccount                : Account.selectors.isHcpAccount(state),
    isCareGiverAccount          : Account.selectors.isCareGiverAccount(state),
    countrySettings             : Account.selectors.countrySettings(state),
    devices                     : App.selectors.devices(state),
    openModalId                 : App.selectors.modal(state),
    route                       : App.selectors.route(state),
    direction                   : App.selectors.direction(state),
    printMode                   : App.selectors.printMode(state),
    visitNotesFloatingModal     : App.selectors.floatingModalSelector(Visit.constants.VISIT_NOTES_FM)(state),
    activeVisit,
    clinicMemberships,
    dataSources                 : DataSources.selectors.dataSources(state),
    patients                    : Hcp.selectors.patients(state),
    menuOpen                    : selectors.menuOpen(state),
    selectedMenuItem            : selectors.selectedMenuItem(state),
    windowWidth                 : selectors.windowWidth(state),
    lastReceivedNotification    : Notifications.selectors.lastReceivedNotification(state),
  };
};


const mapDispatchToProps = (dispatch) => ({
  onValidateAccount               : () => dispatch(Account.actions.validateAccount()),
  onFetchCountrySettings          : () => dispatch(Account.actions.fetchCountrySettings()),
  onManageOrganizationMemberships : () => dispatch(Account.actions.manageAidOrganizationMemberships()),
  onFetchDevices                  : () => dispatch(App.actions.fetchDevices()),
  onCloseDropdown                 : () => dispatch(App.actions.closeDropdown()),
  onFetchDataSources              : () => dispatch(DataSources.actions.fetchDataSources()),
  onCheckPendingMemberships       : () => dispatch(ClinicManagement.actions.checkPendingOrganizationMemberships()),
  onFetchClinicMemberships        : (clinicId) => dispatch(ClinicManagement.actions.fetchClinicMemberships(clinicId)),
  onCheckPatientsWithoutLeadingHcp: () => dispatch(ClinicManagement.actions.checkPatientsWithoutLeadingHcp()),
  setPrintMode                    : (printMode) => dispatch(App.actions.setPrintMode(printMode)),
  setMenuOpen                     : (menuOpen) => dispatch(actions.setMenuOpen(menuOpen)),
  setSelectedMenuItem             : (selectedMenuItem) => dispatch(actions.setSelectedMenuItem(selectedMenuItem)),
  setWindowWidth                  : (windowWidth, shouldSetBaseMenuOpen) => dispatch(
    actions.setWindowWidth(windowWidth, shouldSetBaseMenuOpen),
  ),
  setDashboardLayout: (layout) => dispatch(PatientResults.actions.setDashboardLayout(layout)),
  // onStartWebsocket  : () => dispatch(App.actions.websocketStart()),
});


const ConnectedMainLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainLayout);

export default withStyles(styles)(ConnectedMainLayout);
