exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3VjW5{top:0;left:0;position:fixed;background:#fff;-webkit-box-shadow:0 4px 20px rgba(50,66,121,.16);box-shadow:0 4px 20px rgba(50,66,121,.16);display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;padding:16px;z-index:1030}._3VjW5,._1ya0s{height:60px;width:100%}._19EY8{width:36px}html[dir=ltr] .R8aP8{margin-right:24px}html[dir=rtl] .R8aP8{margin-left:24px}.R8aP8 form{width:100%}.R8aP8 .form-group{margin-bottom:0}._3UmkW{opacity:.3}._3UmkW>*{pointer-events:none}._3Z_DD{margin:20px;width:1px;height:100%;background:#e0e8f2}._3iIYG{display:none}._33B80{-ms-flex:1 1;flex:1 1;position:relative}._1mTxN{position:absolute;top:50px;width:100%;max-width:430px;background:#fff;padding:16px;border-radius:8px;z-index:1030;overflow:hidden}", ""]);

// exports
exports.locals = {
	"topBar": "_3VjW5",
	"topBarHolder": "_1ya0s",
	"logo": "_19EY8",
	"element": "R8aP8",
	"element--isDisabled": "_3UmkW",
	"separate": "_3Z_DD",
	"hide": "_3iIYG",
	"search": "_33B80",
	"searchModal": "_1mTxN"
};