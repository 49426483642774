import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import Link from 'components/Link';
import App from 'modules/App';
import messages from '../../../messages';
import styles from '../DownloadDataModal.pcss';


interface Props {
  countryId: number
}


const b = (chunk: ReactNode): ReactNode => <b>{ chunk }</b>;


const AppStoreLinks: FC<Props> = ({ countryId }) => {
  const country = useSelector(App.selectors.countryById(countryId));
  const iosUrl = get(country, 'settings.iosStoreURL', null);
  const androidUrl = get(country, 'settings.androidStoreURL', null);

  return (
    <div className="mt-6">
      <p className="brand__paragraph brand__fontSecondary">
        <FormattedMessage
          {...messages.infos.appStoreLinksInfo}
          values={{ b }}
        />
      </p>
      <Link
        to={iosUrl}
        className={styles.warnings__stores}
        target="_blank"
      >
        <img src="/assets/svg/ios.svg" alt="" />
        <div>
          <p className="brand__paragraph--bold">
            <FormattedMessage {...messages.labels.iOSStore} />
          </p>
          <p className="brand__caption">
            <FormattedMessage {...messages.labels.forIOS} />
          </p>
        </div>
      </Link>
      <Link
        to={androidUrl}
        className={styles.warnings__stores}
        target="_blank"
      >
        <img src="/assets/svg/android.svg" alt="" />
        <div>
          <p className="brand__paragraph--bold">
            <FormattedMessage {...messages.labels.androidStore} />
          </p>
          <p className="brand__caption brand__fontSecondary">
            <FormattedMessage {...messages.labels.forAndroid} />
          </p>
        </div>
      </Link>
    </div>
  );
};

export default AppStoreLinks;
